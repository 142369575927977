import React, {useEffect, useState} from "react";
import * as T from "../../../../../controller/company-ctr/types/types-billings";
import {
  OEM_PROGRAM,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE, url,
} from "../../../../../service/constant";
import {getDataMicroService} from "../../../../../service/applicatif/back-end-service";
import {toast} from "react-toastify";

const Receipts = (props: T.TPropsReceipts) => {
  const showReceiptHistory = props?.transactionDate?.begin && props?.transactionDate?.end;
  const [lastInvoice, setLastInvoice] = useState<{
    paid_at: string
  }>();

  useEffect(() => {
    if (props.userObject && props.userObject.id) {
      getDataMicroService(
          url.microservice.payment.get_user_invoices + '?paid&last',
          props.userObject.roles.indexOf(OEM_PROGRAM) !== -1
              ? { oem_program_id: props.oemProgramObject?.id }
              : {},
          props.userObject.token
      )
          .then(response => {
            if (response.data.message === 'success') {
              if(response.data.data.invoices.length !== 0) {
                setLastInvoice(response.data.data.invoices[0])
              }
            } else {
              throw response.data.data.detail
            }
          })
          .catch(error => {
            console.log(error)
            toast('Error getting receipts')
          })
    }
  }, [props.oemProgramObject?.id, props.userObject, props.userObject?.roles]);

  return (
    <>
      <div className="itemBilling">
        <h2 className="title">Receipts</h2>
        <div className="contentItem">
          <div className="itemctt">
            <div className="itemctt">
              {showReceiptHistory &&
                  <b>
                    {lastInvoice && `Last receipt: ${lastInvoice.paid_at.split('-')[0]}`}
                  </b>
              }
            </div>
          </div>
          {lastInvoice &&
              <div className="footerItem" style={{ fontSize: "14px" }}>
                {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
                <a
                    onClick={
                      [
                        OEM_PROGRAM_EDITOR,
                        OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                      ].indexOf(props?.roleUserIncurrentProgram) !== -1
                          ? props?.setShowModalPermissionOem
                          : props?.showViewPayement
                    }
                >
                  View details
                </a>
              </div>
          }
        </div>
      </div>
    </>
  );
};

export default Receipts;
