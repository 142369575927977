import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as T from "../../../../../controller/company-ctr/types/types-billings";
import { getDataMicroService } from "../../../../../service/applicatif/back-end-service";
import {
  OEM_PROGRAM,
  OEM_PROGRAM_EDITOR,
  OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
  url,
} from "../../../../../service/constant";

const TransactionHistory = (props: T.TPropsTransactionHistory) => {
  // const showTransactionHistory = props?.transactionDate?.begin && props?.transactionDate?.end;
  const [firstTransaction, setFirstTransaction] = useState<string>();
  const [lastTransaction, setLastTransaction] = useState<string>();

  useEffect(() => {
    if (props.userObject && props.userObject.id){
      getDataMicroService(
          url.microservice.payment.get_user_transactions + "?first",
          props.userObject.roles.indexOf(OEM_PROGRAM) !== -1
              ? {oem_program_id: props.oemProgramObject?.id}
              : {},
          props.userObject.token
      )
          .then((response) => {
            if (response.data.message === "success") {
              if (response.data) {
                setFirstTransaction(
                    response?.data?.data?.created || response?.data?.data
                );
              }
            } else {
              throw response.data.data.detail;
            }
          })
          .catch((error) => {
            console.log(error);
            toast("Error getting receipts");
          });

        getDataMicroService(
            url.microservice.payment.get_user_transactions + "?last",
            props.userObject.roles.indexOf(OEM_PROGRAM) !== -1
                ? {oem_program_id: props.oemProgramObject?.id}
                : {},
            props.userObject.token
        )
            .then((response) => {
              if (response.data.message === "success") {
                if (response.data) {
                  setLastTransaction(
                      response?.data?.data?.created || response?.data?.data
                  );
                }
              } else {
                throw response.data.data.detail;
              }
            })
            .catch((error) => {
              console.log(error);
              toast("Error getting receipts");
            });
    }
  }, [props.oemProgramObject?.id, props.userObject, props.userObject?.roles]);

  return (
    <>
      <div className="itemBilling">
        <h2 className="title">Transaction history</h2>
        <div className="contentItem">
          <div className="itemctt">
            <b>
              {lastTransaction && `Last transaction: ${lastTransaction?.split("-")?.[0]}`}
            </b>
          </div>
          {firstTransaction && (
            <div className="footerItem" style={{ fontSize: "14px" }}>
              {/* //dev #47985 V2.1 [OEM/Ecosystem] donner l'accès au "Billing center" pour le rôle "Outreach representative" ET "Editor" */}
              <a
                onClick={() =>
                  [
                    OEM_PROGRAM_EDITOR,
                    OEM_PROGRAM_OUTREACH_REPRESENTATIVE,
                  ].indexOf(props?.roleUserIncurrentProgram) !== -1
                    ? props?.setShowModalPermissionOem(true)
                    : props?.showViewPayement()
                }
              >
                View details{" "}
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionHistory;
